.TurfCard{
    width: 80%;
    height: auto;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
    padding: 2px 0px;
    cursor: pointer;
    color: black;
    text-decoration: none;
}
@media screen and (max-width:768px) {
    .TurfCard{
        margin: 15px !important;
        width: 90%
    }
}
.TurfCard:hover{
    color: black;
    text-decoration: none;
}
.TurfCard:first-child{
    margin-right: 20px;
}
.TurfCard:nth-child(2){
    margin: 0px 20px;
}
.TurfCard:last-child{
    margin-left: 20px;
}
.turfCardLogo{
    width: 100px;
    height: 100px;
    display: flex;
    margin-right: 30px;
}
.turfCardContent{
    padding: 15px 15px 0 15px;
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-basis: 100%;
}
.lhs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.rhs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-left: auto;
}
.rhs .timings, .rhs .price{
    display: flex;
    flex-direction: row;
}
.rhs .timings p, .rhs .price p{
    margin: 0 0 0 5px;
    font-style: italic;

}
.lhs .sportContainer{
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    
}
.lhs .sportContainer img{
    margin-right: 5px;
}
.sportContainer{
    margin-top:10px;
    margin-bottom: 10px;
}
.discount{
    background:  #2D328F;
    border-radius: 10px;
    text-align: center;
    margin: 15px 0;
    padding: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: white;
}


.clockImageStyle{
    margin-right: 5px;
}

.activityImageStyle{
    margin-right: 20px;
}

.container2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
.turfImage{
    border-radius: 10px;
}
.clockContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.clockContainer p{
    margin-bottom: 0px;
    margin-left: 5px;
}