.App {
  text-align: center;
}
.nav{
  position: fixed;
  top: 0%;
  display: flex;
  padding: 10px 20px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  z-index: 100;
  background-color: #2D328F;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);}
.navLogo{
  height: 60px;
  justify-self: flex-start;
}
.nav a{
  align-self: center;
  color: white;
  text-decoration: none;
  transition: 0.2s;
}
@media screen and (max-width:500px) {
  .linkDiv{
    margin-right: -15px;
  }
  .navLogo{
    height: 45px;
  }
}
.linkDiv{
  display: flex;
}
.linkDiv a{
  align-self: center;
  margin: 0 10px;
}
lottie-player{
  margin: auto;
  display: flex;
  justify-self: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
