html, body, #root {
    height:100%;
}
.abcRioButton.abcRioButtonLightBlue{
    background: #D5D6E7;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}
.signinDiv{
    
}
.g-signin2{
    align-self: center;
}
#Login{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}
.logo{
    align-self: center;
    margin-bottom: 10px;
}
.welcome{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    color: #000000;
    align-self: center;
    margin-bottom: 40px;
}

.welcome-sub-text{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
    color: #000000;
    align-self: center;
    margin-bottom: 100px;   
    text-align: center;

}
 
.elclasico{
    color:#2D328F;
}
.passwordDiv{
    position: relative;
}
.fa-eye{
    top: 32%;
    position: absolute;
    right: 10px;
    cursor: pointer;
}