.adminCard {
    background: #FAFAFA;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    padding: 18px;
    /* display: flex;
    flex-direction: row; */
    margin: 20px 0;
}

.stripScreen {
    background: white !important;
}

.adminDate {
    position: absolute;
    top: 40px;
}

#venues {
    display: block;
    margin: auto;
    left: 0%;
    cursor: pointer
}

.adminCalendar {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer
}
.adminCardContainer{
    min-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (max-width:768px) {
    .rhs {
        margin-left: 0;
        align-items: flex-start;
    }

    .adminCard {
        flex-direction: column;
        padding: 20px 40px;
    }
}