.stripScreen{
    background: #f0f0f0;
    width: 40%;
    margin: 80px auto 0;
    padding: 20px 40px 80px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    min-height: 100%;
}
@media screen and (max-width:768px) {
    .stripScreen{
        width: 100%;
        padding: 20px 10px 80px;
    }
}
.BPHeader{
    margin-bottom: 30px;
}
.confirmBooking{
    background: crimson;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    border: none;
    padding: 15px 20px;
    width: inherit;
    align-self: center;
    position: fixed;
    bottom: 10px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border-radius: 8px;
}
.cbl{
    justify-self: flex-start;
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.cbl p{
    text-align: left;
    margin: 0;
    font-weight: 400;
}
.cbr{
    justify-self: flex-end;
    margin: 3% 0;    
}
#BookingPreferences{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}
#bookingDate{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 40px;
}
.dates{
    background-color: #f2f3f5;
    color:  rgb(26, 0, 119);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    border-radius: 8px;
    flex-direction: column;
    font-size: medium;
    padding: 10px;
    margin-top: 10px;

}

.dates p{
    font-size: medium;
    margin-bottom: 0px;
}
.dates:hover{
    background-color:  rgb(59, 6, 252);
}
.dates.active{
    background-color: rgb(26, 0, 119);
    color:  #FFFFFF;
    font-weight: 600;
}
.fadedText{
    color: #496581;
    margin-bottom: 5px;
}
.sportButtonDiv{
    margin-bottom: 30px;
    margin-top: 10px;
}
.sportButton{
    background: #FFFFFF;
    border-radius: 9px;
    border: #FFFFFF;
    margin-right:30px ;
    padding: 8px 18px;
}
.sportButton.active{
    border: 2.5px solid #2D328F;
}
#timeSlots{
    display: grid;
    grid-template-columns: auto auto auto;
    flex-flow: wrap;
    flex-direction: row;
    margin-bottom: 40px;
    margin-top:10px;
    gap: 3px;
}
.slotBtn{
    padding: 8px 5px;
    background-color: #f2f3f5;
    color: rgb(26, 0, 119);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px;
    transition: 0.5s;
    border-radius: 8px;
    flex-direction: column;
}
.slotBtn:hover{
    background-color:  rgb(59, 6, 252);
}
.slotBtn.picked{
    background-color:  rgb(26, 0, 119);
    color:#FFFFFF;
}
.col_right{
    text-align: right;
}
.priceDiv h4{
    margin-bottom: 30px;
}
.priceDiv .row{
    padding: 0 20px;
}
.priceDiv{
    margin-bottom: 40px;
}
.cancellationHeading{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.cancellationHeading b{
    text-align: left!important;
}
.closeModalSpan{
    cursor: pointer;
    color: rgb(180, 3, 3);
    font-weight: 700;
    width:5%;
}
.modalContainer,.cancelModalContainer{
    position: fixed;
    height: 120%;
    width: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    justify-content: center;
    align-items: center;
    display: none;
    left: 0;
}
.modal{
    height: auto;
    width: 400px;
    background-color: white;
    display: block;
    position: relative;
    margin:0 auto;
    padding: 30px 50px;
    top: 17%;
    border-radius: 8px;
    box-shadow: 1px 1px 8px grey;
}
#registerModal .modal{
    top: 2%;
}
.modal table{
    margin-top: 20px;
}
.modal td{
    padding: 10px 0;
}
.modal tr td:nth-child(2){
    text-align: right;
}
.modal b{
    text-align: center;
    width: 100%;
    display: block;
}
button.btn-primary{
background-color: #2D328F;
}
.confirmButtonContainer{
    width: max-content;
    justify-content: flex-end;
    display: flex;
    position: fixed;
    right: 16px;
    bottom: 16px;
}
.confirmButtonContainer button{
    border-radius: 24px;
}
.stripScreen{
    overflow-x: hidden;
}