.userName{
    color: #2D328F;
}
#LandingPage{
    margin-top: 30px;
    padding: 50px 30px;
    overflow-x: hidden;
}
@media screen and (max-width:768px) {
    .banner{
        display: none;
    }
}
.item img{
    max-height: 300px;
}
.banner{
    width: 100%;
    height: 300px;
    background: #ffffff;
    border-radius: 7px;
}
.turfCardContainer{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}
@media screen and (max-width:768px) {
    .turfCardContainer{
        flex-direction: column;
        align-items: flex-start;
    }
    #LandingPage{
        padding: 50px 5px;
        overflow-x: hidden;
    }
}
.greeting{
    margin: 10px 20px 20px 20px;
}

.subTitleText{
    font-style: italic;
}