.cancel{
    background: #E84A5F;
    border: 1.5px solid #F52D2D;
    text-align: center;
    margin: 15px 0;
    padding: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
}
.turfCardLogo{
    margin-right: 0px;
}
.cancelContainer{
    border-top: 1px solid #2A547E;
}
.bookingRHS{
    align-items: flex-start;
}
#bookingCard{
    background: #FAFAFA;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    padding: 15px;
    margin-bottom: 30px;
}
.priceImageStyleStyle{
    display:flex;
    flex-direction: row;
}
.priceImageStyleStyle lhs{
    justify-self:flex-start;
}
@media screen and (max-width:400px) {
    #Bookings{
        margin-top: 120px;
    }
}
@media screen and (max-width:768px) {
    #Bookings{
        padding-left: 0;
        padding-right: 0;
    }
    .turfCardLogo{
        width: 70px;
        height: 70px;
    }
    .priceImageStyleStyle{
        justify-content: space-evenly;
    }
}